import React from "react";
import {Link} from 'react-router-dom';

function Navigation() {
    return (
        <>
            <Link to = '/'>Cleanse</Link>
            <Link to = '/about/'>About</Link>
            <a href = 'https://harrycb.com/contact/' target='_blank'>Contact</a>
        </>

);
}

export default Navigation;