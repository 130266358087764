import React from "react";
import {typeCleanse} from './../js/functions.js'
function About() {
    return (
        <div className="container about">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1>About</h1>
                    <p>TypeCleanse, born out of the need to rapidly process text strings.</p>
                    <p>Simplify file-naming and URL creation. Rapidly remove extra spaces from text and Generate Lorem ipsom.</p>
                    <p>Developed by <a href="https://harrycb.com/" target="_blank">Harry Courtenay-Bishop</a>.</p>
                </div>
            </div>
        </div>

    );
}

export default About;
