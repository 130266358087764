// Store Core App Functions

export const typeCleanse = {
    cleanseOption: function () {
        var cleanseSelection = document.querySelector(".cleanse-selection").value;
        var textAreaContent = document.querySelector(".textarea");

        if(cleanseSelection == "generateLoremIpsum"){
            this.generateLoremIpsum(textAreaContent);
        }

        //Handle generic error messages here
        else if(textAreaContent.value == ""){
            this.status('Insert text');
        }
        else if(cleanseSelection == "empty"){
            this.status('Select cleanse option');
        }

        //Start calling functions
        else if(cleanseSelection == "wordCount"){
            this.wordCount(textAreaContent);
        }
        else if(cleanseSelection == "characterCount"){
            this.characterCount(textAreaContent);
        }
        else if(cleanseSelection == "dateSuffixUk"){
            this.dateSuffixUk(textAreaContent);
        }
        else if(cleanseSelection == "dateSuffixUs"){
            this.dateSuffixUs(textAreaContent);
        }
        else if(cleanseSelection == "lowercase") {
            this.lowercase(textAreaContent);
        }
        else if(cleanseSelection == "uppercase") {
            this.uppercase(textAreaContent);
        }
        else if(cleanseSelection == "kebabCase") {
            this.kebabCase(textAreaContent);
        }
        else if(cleanseSelection == "snakeCase") {
            this.snakeCase(textAreaContent);
        }
        else if(cleanseSelection == "capitalisationCase") {
            this.capitalisationCase(textAreaContent);
        }
        else if(cleanseSelection == "andToAmpersand") {
            this.andToAmpersand(textAreaContent);
        }
        else if(cleanseSelection == "ampersandToAnd") {
            this.ampersandToAnd(textAreaContent);
        }
        else if(cleanseSelection == "removeAllSpaces") {
            this.removeAllSpaces(textAreaContent);
        }
        else if(cleanseSelection == "removeLineBreaks") {
            this.removeLineBreaks(textAreaContent);
        }
        else if(cleanseSelection == "createLineBreaks") {
            this.createLineBreaks(textAreaContent);
        }
        else if(cleanseSelection == "singleSpaces") {
            this.singleSpaces(textAreaContent);
        }
        else if(cleanseSelection == "removeAllSpecialCharacters") {
            this.removeAllSpecialCharacters(textAreaContent);
        }
        else if(cleanseSelection == "stripHtmlTags") {
            this.stripHtmlTags(textAreaContent);
        }
    },
    lowercase: function (x) {
        var lowercase = x.value.toLowerCase();
        if(x.value === lowercase ){
            this.status('Text already lowercase');
        }
        else{
            x.value = lowercase;
            this.status('Text lowercase');
        }
    },
    uppercase: function (x) {
        var uppercase = x.value.toUpperCase();
        if(x.value === uppercase){
            this.status('Text already uppercase');
        }
        else{
            x.value = uppercase;
            this.status('Text uppercase');
        }
    },
    kebabCase: function (x) {
        //Remove spaces from each side of string, convert line breaks to single spaces, remove all special characters, remove all extra spaces and replace single spaced with -.
        var kebabCase = x.value.toLowerCase().trim().replaceAll(/(\r\n|\n|\r)/gm, ' ').replaceAll(/[^a-zA-Z0-9 ]/g, '').replaceAll(/\s\s+/g, ' ').replaceAll(/ /g, '-');
        //Check for no spaces otherwise will remove dashes
        var removedSpaces = x.value.replaceAll(/ /g, '');
        var lineBreaks = /(\r\n|\n|\r)/gm;
        //Test for linebreaks
        if(x.value == removedSpaces && !lineBreaks.test(x.value)){
            this.status('Unable to generate kebab-case');
        }
        else{
            x.value = kebabCase;
            this.status('Text kebab-case');
        }
    },
    snakeCase: function (x) {
        //Remove spaces from each side of string, convert line breaks to single spaces, remove all special characters, remove all extra spaces and replace single spaced with _.
        var snakeCase = x.value.toLowerCase().trim().replaceAll(/(\r\n|\n|\r)/gm, ' ').replaceAll(/[^a-zA-Z0-9 ]/g, '').replaceAll(/\s\s+/g, ' ').replaceAll(/ /g, '_');
        //Check for no spaces otherwise will remove dashes
        var removedSpaces = x.value.replaceAll(/ /g, '');
        var lineBreaks = /(\r\n|\n|\r)/gm;
        //Test for linebreaks
        if(x.value == removedSpaces && !lineBreaks.test(x.value)){
            this.status('Unable to generate snake_case');
        }
        else{
            x.value = snakeCase;
            this.status('Text snake_case');
        }
    },

    capitalisationCase: function (x) {

        //Remove spaces from each side of string, convert line breaks to single spaces, remove all special characters, remove all extra spaces and replace single spaced with _.
        let capitalisationCase = x.value.toLowerCase().trim().replaceAll(/(\r\n|\n|\r)/gm, ' ').replaceAll(/\s\s+/g, ' ');
        //Split into array
        let words = capitalisationCase.split(" ");
        let capitalisationCaseString = '';
        for ( let i = 0; i < words.length; i++)
        {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            capitalisationCaseString = capitalisationCaseString + words[i] + ' ';
        }

        capitalisationCaseString = capitalisationCaseString.trim();

        if(x.value == capitalisationCaseString){
            this.status("Unable to convert Capitalisation Case");
        }
        else{
            x.value = capitalisationCaseString
            this.status("Text Capitalisation Case");
        }

    },
    ampersandToAnd: function (x) {
        var ampersandToAnd = x.value.replaceAll('&', 'and');

        if(x.value ==  ampersandToAnd){
            this.status("Unable to convert '&' to 'and'");
        }
        else{
            x.value = ampersandToAnd;
            this.status("Ampersands converted to 'and' ");
        }
    },
    andToAmpersand: function (x) {

        // Do Regex to see characters either side of 'and'
        var andToAmpersand = x.value.replaceAll(' and ', ' & ');

        if(x.value == andToAmpersand){
            this.status("Unable to convert 'and' to '&'");
        }
        else{
            x.value = andToAmpersand;
            this.status("All occurrences of 'and' converted to ampersands");
        }

    },

    dateSuffixUk: function (x) {
        var today = new Date();
        var date = ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth()+1)).slice(-2) + '-' + today.getFullYear();
        var addedDate =  x.value + '-' + date ;
        if(x.value.includes(date)){
            this.status('Date already added');
        }
        else{
            x.value = addedDate;
            this.status('Date added');
        }
    },
    dateSuffixUs: function (x) {
        var today = new Date();
        var date = ('0' + (today.getMonth()+1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + '-' + today.getFullYear();
        var addedDate =  x.value + '-' + date ;
        if(x.value.includes(date)){
            this.status('Date already added');
        }
        else{
            x.value = addedDate;
            this.status('Date added');
        }
    },
    removeLineBreaks: function (x) {
        var removedLineBreaks = x.value.replaceAll(/(\r\n|\n|\r)/gm,"");
        //Space after period
        removedLineBreaks = removedLineBreaks.replaceAll(/\s*([,.!?:;]+)(?!\s*$)\s*/g, '$1 ')

        if(x.value === removedLineBreaks){
            this.status('All line breaks already removed');
        }
        else{
            x.value = removedLineBreaks;
            this.status('All line breaks removed');
        }
    },
    createLineBreaks: function (x) {
        var singleSpaces = x.value.replaceAll(/\s\s+/g, ' ').trim();
        var createLineBreaks = singleSpaces.replace(/ /g, '\n');

        if(x.value === createLineBreaks){
            this.status('Line breaks already after every word');
        }
        else{
            x.value = createLineBreaks;
            this.status('Line breaks generated after every word');
        }
    },
    singleSpaces: function (x) {
        var singleSpaces = x.value.replaceAll(/\s\s+/g, ' ').trim();
        var removedSpaces = x.value.replaceAll(/ /g, '');
        if(x.value === removedSpaces){
            this.status('Already has no spaces');
        }
        else if(x.value === singleSpaces){
            this.status('Already has single spaces');
        }
        else{
            x.value = singleSpaces;
            this.status('All extra spaces removed');
        }
    },
    removeAllSpecialCharacters: function (x) {
        var removedSpecialCharacters = x.value.replaceAll(/[^a-zA-Z0-9 ]/g, ' ');
        if(x.value === removedSpecialCharacters){
            this.status('No special characters');
        }
        else{
            x.value = removedSpecialCharacters;
            this.status('All special characters removed');
        }
    },
    removeAllSpaces: function (x) {
        var removedSpaces = x.value.replaceAll(/ /g, '');

        if(x.value === removedSpaces){
            this.status('All spaces already removed');
        }
        else{
            var removedSpaces = x.value.replaceAll(/ /g, '');
            x.value = removedSpaces;
            this.status('All spaces removed');
        }
    },
    copyText: function () {
        var textAreaContent = document.querySelector(".textarea");
        if(textAreaContent.value == ""){
            this.status('Insert text');
        }
        else{
            navigator.clipboard.writeText(textAreaContent.value);
            this.status('Text copied to clipboard');
        }
    },
    resetText: function () {
        var textAreaContent = document.querySelector(".textarea");
        if(textAreaContent.value == ""){
            this.status('Insert text');
        }
        else{
            textAreaContent.value = "";
            this.status('Text reset');
        }
    },
    stripHtmlTags: function (x) {
        var strippedHtmlTags = x.value.replace(/<[^>]*>/g, '');

        if(x.value ==  strippedHtmlTags){
            this.status("Unable to strip HTML tags");
        }
        else{
            x.value = strippedHtmlTags;
            this.status("HTML tags stripped");
        }
    },
    generateLoremIpsum: function (x) {
            var lorumIpsom = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget nunc diam. Maecenas in gravida ipsum. Aliquam vel nunc non lorem vehicula bibendum vel quis ligula. Sed justo neque, sagittis non luctus in, vestibulum semper ipsum. Nam diam magna, luctus eget libero quis, ultricies bibendum nisl. Pellentesque gravida sagittis posuere. Aliquam consequat elit et velit tempor, at iaculis mi vulputate. Nullam ut dui vel magna convallis tempor eget nec velit. Quisque porttitor finibus fringilla. Sed tellus ipsum, iaculis pretium ligula vel, auctor lacinia nulla. Fusce consectetur maximus tellus, eget egestas libero varius eget. Nunc vitae tempor ligula. Sed sed mauris sit amet enim finibus tincidunt vel sed massa. Nulla fringilla ante vitae condimentum consequat.\n" +
            "\n" +
            "Suspendisse ac ipsum laoreet, pellentesque purus ut, gravida sapien. Suspendisse ut nisi quis eros dapibus suscipit eget facilisis dui. Praesent quis erat vestibulum, aliquam tortor porttitor, blandit dui. Donec ligula neque, sodales nec est a, ornare faucibus nibh. Pellentesque placerat sagittis tristique. Duis laoreet, dui quis pretium pharetra, magna est lacinia tellus, in consectetur nisl nunc in eros. Etiam ornare facilisis urna eget sagittis. Maecenas pulvinar tortor vulputate, placerat purus vitae, ultricies quam. Aliquam scelerisque sem id lectus suscipit, ac placerat nulla porttitor. Curabitur fringilla erat et semper vestibulum. Aenean ultrices nisi in molestie molestie.\n" +
            "\n" +
            "Curabitur condimentum odio quis enim egestas rutrum. Integer sodales accumsan arcu et condimentum. Proin luctus accumsan purus, vel suscipit ante rhoncus vel. Nam maximus odio vitae quam iaculis pulvinar. Nulla vel efficitur dui, ac euismod purus. Vestibulum aliquam nisi vel fringilla rhoncus. Suspendisse rhoncus auctor mauris, nec vestibulum elit. Praesent hendrerit fermentum tellus nec interdum. Cras sollicitudin placerat nulla a sagittis. Nunc ligula enim, sagittis in tincidunt ac, egestas id dui.\n" +
            "\n" +
            "Curabitur suscipit vestibulum eros, sit amet porttitor enim venenatis eu. Ut ac massa id ante aliquam fermentum non quis justo. Sed lectus est, accumsan vitae blandit rutrum, tempus at orci. Nam augue ipsum, consectetur vel molestie vel, ultricies ac sapien. Mauris vestibulum sed eros at viverra. Aenean ut mauris id elit tincidunt vehicula ac placerat odio. Nulla commodo molestie commodo.\n" +
            "\n" +
            "Suspendisse placerat nisi turpis, sed mattis quam tempor ut. Sed vel ultricies ipsum. Nam dignissim hendrerit erat, vel semper nulla pellentesque quis. Ut id neque tempor, pharetra turpis vel, mattis massa. Quisque in purus in nulla condimentum cursus. Curabitur consectetur lacus nec dui pulvinar auctor. Nam urna est, euismod vitae laoreet quis, efficitur in erat. Phasellus bibendum, libero a vulputate feugiat, tellus sapien sodales enim, at viverra enim sem vitae turpis. Sed consectetur nisi nec quam commodo imperdiet. Sed volutpat suscipit enim. Sed sit amet tempus tortor, quis pharetra est. Curabitur eget interdum ligula, in dictum massa. Morbi commodo vestibulum lorem, vel sollicitudin mi efficitur ac. Ut et ex in sem ultrices mattis sed at tellus. Pellentesque vitae porttitor arcu.";

        if(x.value === lorumIpsom){
            this.status('Already lorum ipsom');
        }
        else{
            x.value = lorumIpsom;
            this.status('Lorem ipsom generated');
        }
    },
    wordCount: function (x) {
        var count = x.value.split(/\s+/).length;
        this.status(count + ' words');
    },
    characterCount: function (x) {
        var count = x.value.length;
        this.status(count + ' characters');
    },
    status: function (message) {
        var status = document.querySelector(".status");
        var textAreaContent = document.querySelector(".textarea");
        status.textContent = message;
        function clearStatus() {
            status.textContent = "";
        }
        window.setTimeout(clearStatus, 2000);
    }
}


